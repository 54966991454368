.infUpload{
    font-style: italic;
    opacity: 0.5;
    font-size: 0.9em;
    padding-left: 0.4em;
    display: block
}
.file-name{
    padding-left: 0.4em;
    display: block;
    font-weight: bold;

}
.sortable-mapping-wrap {
    & > div {
        height: 2.333em;
        padding: 0.5556em 1.5556em;
        &:nth-child(odd) {
            background: $blue-ultra-lightest;
        }
    }
}
.on-off_button {
    padding: 7px;
    color: $white;
    margin: 0px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    width: 38px;
    border: 1px solid $grey-darker;
    background-color: $grey-darker;
    cursor: pointer;
    height: 38px;
}
.on_selected {
    border: 1px solid $green-light;
    background-color: $green-light;
}
.off_selected {
    border: 1px solid $red;
    background-color: $red;
}
.on-offmiddle {
    padding-left: 2rem !important;
}
.ng-invalid {
    .k-dropdown-wrap {
        color: $grey-darkest !important;
    }
}
.multiselect-clearall {
    float: right;
    color: $secondary-blue !important;
    font-weight: bold;
    font-size: 0.7em;
}
.anchorEle {
    cursor: pointer !important;
    color: $secondary-blue !important;
}
.form-ctrl-wrap {
    width: calc(100vw - 24em);
    kendo-grid{
        width: 100%;
    }
}
.padding-wrap {
    padding-bottom: 2em;
    padding-top: 2em;
}
.padding-wrap-chk {
    padding-bottom: 2em;
    padding-top: 1em;
}
.agencies-bottom-border {
    border-bottom: none !important;
    margin-top: -2em;
    padding-bottom: 0;
}
.lbl-display {
    color: $grey-darkest;
    word-break: break-word;
    word-wrap: break-word;
}
.list-link {
    width: 1em;
    height: 1em;
    display: inline-block;
}
.usr-dtl {
    margin-top: -2.4em;
}
.lbl-title {
    font-weight: bold;
}
.green-hr {
    border-color: $primary-green !important;
    color: $primary-green !important;
    margin-bottom: 20px !important;
}
.cmmnts-popup {
    width: 100% !important;
    padding: 5px !important;
}
.exclamationOnTab {
    color: $red !important;
    font-size: 20px !important;
    margin-right: 4px;
}
.clsIsNegative .codeColumn {
    color: $red !important;
}
.k-disabled {
    background-color: $grey-light;
}
.date-help-block {
    display: inline-block;
    height: 20px;
    color: $red;
    width: 250px !important;
}
.zipCode.k-widget.k-numerictextbox.ng-dirty.ng-invalid > .k-numeric-wrap {
    color: $black !important;
}
.agreement-header-custom {
    margin-top: -17px;
}
.custom-font {
    color: $black !important;
    font-weight: bold !important;
}
.border-style {
    border-width: thin;
    border-left-width: 1px !important;
}
.profile-form-wrap {
    .k-form-field {
        display: block !important;
    }
}
:-ms-input-placeholder {
    color: $grey-ultra-dark !important;
}
#shift-injector,
#dropdown-icon {
    .k-dropdown-wrap .k-select {
        display: inherit;
        .k-input {
            padding-bottom: 4px !important;
        }
    }
}
.action-link {
    color: $secondary-blue !important;
}
.success {
    color: $green;
}
.error {
    color: $red;
}
.warning {
    color: $yellow-dark;
}
.bluewarning {
    color: $secondary-blue;
}
.Active {
    color: $primary-green !important;
}
.Incomplete,
.Onhold {
    color: $yellow-dark !important;
}
.Inactive,
.Terminated {
    color: $red !important;
}
.tick {
    color: $green;
}

.cross {
    color: $red;
}

.warning {
    color: $yellow-dark;
}
.cicle {
    color: $grey-ultra-dark;
}
.top_0 {
    top: 0.5px !important;
}
a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover{
    color:inherit;
}
.ctrl-sm{
    & .k-combobox-popup{
        width:16.75em!important;
    }

    .autoCompleteControl{
        width: 100%;

        .k-combobox-popup{
            width: 100% !important;
        }

        .look-over-btn{
            right: 20px !important;
        }
    }
}
.list-mar-btm{
    & li{
        display: flex;
        align-items: center;
        margin-bottom:10px;
        kendo-svgicon{
          margin-right: 10px;
        }
    }
}
.cust{
    &-height{
        height:3.5em!important;
        &-sm{
            & .k-input{
                height:2.1em!important;
            }
        }
    }
}
.l-h-0 {
    line-height: 1.4em!important;
}
.no-loader .k-loading-image{
    display: none !important;
}
.masked-textbox-style {
    input {
        height: 3.182em !important;
    }
}
.readonly, .readonly:focus, .readonly:hover{
    background-color:$grey-lighter;
}
.multipleCodesoptions ul.k-reset{
    max-height:115px;
    overflow: auto;
}
::-webkit-input-placeholder {
    color: $grey!important;
  }
::-moz-placeholder {
    color: $grey!important;
  }
  :-ms-input-placeholder {
    color: $grey!important;
  }
  :-moz-placeholder {
    color: $grey!important;
  }

.k-switch-off .k-switch-container {
    background-color: $blue-dark !important;
}

.k-switch-label-on, .k-switch-label-off {
    text-shadow: none;
    display: none;
}
.separator{
    &-comma{
        &:after{
            content:",";
        }
        &:last-child{
            &:after{
                content:"";
            }
        }
    }
}
.div{
    &-readonly{
        background-color: $grey-lighter;
    }
}
.mail-notification{
    background: url("../../assets/icons/Message.svg") no-repeat;
    width: 1em;
    height: 0.8em;
    font-size: 20px;
    display: inline-block;
    cursor: pointer;
    margin:20px;
    & span{
        margin: 0;
        padding: 1px 3px;
        background-color: $red;
        border-radius: 100%;
        font-size: 10px;
        display: inline-block;
        position: relative;
        right: -10px;
        top: -10px;
        min-width:17px;
        min-height:17px;
        text-align:center;
    }
}

.wwl-alignment,
.form-wrap-alignment{
    padding-left: 0px !important;
    margin-bottom: 30px;
}
.qinfo {
    font-size: 1.03em;
    background-color: $yellow-dark;
    color: $black;
    padding: 5px 10px;
    font-weight: bold;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 5px;

    & i {
        font-style: normal;
        font-weight: bolder;
        font-size: 1.5em;
        display: inline-block;
        padding-right: 5px;
    }

    & a {
        color: $secondary-blue !important;
    }
}
.horizontal-divider{
    margin-top:25px;
    margin-bottom:15px;
    height:1px;
    width:100%;
    border-top:1px solid gray;
}

.form-wrap.no-bottom-border{
    border-bottom: none !important;
}
.side-nav-collapsed{
    & .b-checkbox{
        & input[type="checkbox"]{
            border:none!important;
            outline:1px solid $grey-darker!important;
        }
    }
    & .b-a-1p{
        border:none!important;
        outline:1px solid $grey-darker!important;
    }
}
.trans-log{
    & kendo-datepicker{
        margin-left:0!important;
        width:100%!important;
    }
    & kendo-combobox.k-combobox{
        left: 0 !important;
        width:100% !important;
        height: 2.85em !important;
        box-shadow: none;
        border-color: transparent !important;
    }
    & autocomplete-box{
        outline: none;
        & > div{
            position: relative;
            .k-combobox-popup{
            .k-searchbar{
                .k-input{
                    border: none;
                    box-shadow: none;
                    width: 100%;
                }
            }
            .k-clear-value{
                top:10px;
                right: 40px;
              }
        }
    }
      button.look-over-btn{
        position: absolute;
        background: url(/assets/icons/Lookup.svg) no-repeat !important;
        width: 30px;
        min-width: 0px !important;
        height: 30px;
        top: 5px !important;
        right: 60px !important;
        svg{
          display: none !important;
        }
    }
    }
    .input-container-width{
        width: calc(50% - 4.5em);
    }
    .button-width{
        width: 9em;
    }
}
.icon-hide .k-select{
    display:none!important;
}

.hide-x{
 .k-clear-value{
  display: none !important;
 }
}
.label {
    display: inline-block;
}

  .k-reset {
    & .k-select {
      & .k-svg-i-close {
        padding-top: 0.5em;
      }
    }
  }

@media only screen and (min-width:740px) and (max-width:1380px){
    .action-head-fixed-height.action-head{
        a i,
        .cdms-btn-refresh .k-svg-i-refresh {
            font-size: 1.25em !important;
        }
        .cdms-btn-refresh {
            padding: 0;
        }
    }
}
