.sidebar {
  width: 17.944em;
  background: $grey-ultra-lighter;
  height: calc(100vh - 5em);
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    background-color: $grey-ultra-light;
  }
  & > ul {
    & > li {
      & > a {
        text-transform: uppercase;
      }
      & ul {
        & li {
          & > a {
            text-transform: uppercase;
          }
          & i {
            padding-right: 1.6em;
            &:before {
              font-family: "cdmsfonts";
              display: inline-block;
              vertical-align: middle;
              line-height: 1;
              font-weight: normal;
              font-style: normal;
              speak: none;
              text-decoration: inherit;
              font-size: 1.5em;
              text-transform: none;
              text-rendering: optimizeLegibility;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\ea02";
            }
            &.custservice:before {
              content: "\ea05" !important;
            }
            &.authorizations:before {
              content: "\ea04";
              font-size: 1.2em;
            }
            &.secondary-item:before {
              content: "\ea04";
              font-size: 1.2em;
            }
            &.shiftmaintenance:before {
              content: "\ea0c";
            }
            &.paycp:before {
              content: "\ea02";
            }
            &.pbr:before {
              content: "\ea02";
            }
            &.usercarriermapping:before {
              content: "\ea07";
            }
            &.agencies:before {
              content: "\ea03";
            }
          }
          &:hover,
          &.selected {
            & > i,
            & > a {
              color: $white;
              &:hover {
                color: $white;
              }
            }
          }
          &[data-url="Maintenance/UserManagement"],
          &[data-url="Maintenance/Configuration"] {
            & > i {
              &:before {
                content: "\ea03";
              }
            }
          }
          &.has-child {
            &:hover {
              & > i,
              & > a {
                color: $blue-ultra-dark !important;
              }
            }
            & > ul {
              & > li {
                &.has-child {
                  & > a {
                    text-transform: uppercase !important;
                  }
                  &:hover {
                    background: transparent;
                    & > ul {
                      & > li {
                        & > a {
                          color: $blue-ultra-dark;
                        }
                      }
                    }
                  }
                  & > ul {
                    & > li {
                      padding: 7px;
                      padding-left: 3.6em;
                      & > a {
                        text-transform: capitalize;
                      }
                      &:hover {
                        & > a {
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.active {
    width: 4.4444em;
    text-align: center;
    padding: 0;

    .collapse:not(.show) {
      display: block;
    }

    li {
      padding: 0 !important;
      &.has-child {
        & > a {
          display: inline-block;
        }
        & > ul {
          & > li {
            i {
              display: none;
            }
          }
        }
      }

      i {
        margin: 0.35em 1em !important;
        &.secondary-item {
          margin: 0;
          display: none;
        }
      }
    }

    & > ul {
      li {
        border-bottom: 0;
      }

      & > li {
        border-bottom: 1px solid $grey-lighter !important;
      }
    }

    a {
      display: none;
    }
  }

  & > ul {
    margin-bottom: 0;
    padding: 1.5em 0 0.5em;
    min-height: calc(100vh - 6.5em);

    & > li {
      margin-bottom: 1.778em;
      border-bottom: 0 !important;

      & > a {
        font-size: 1.11em;
        font-family: $primary-font;
        color: $primary-green;
        padding: 0 1.889em;
        &::after {
          margin-left: 1em;
        }
        &.collapsed {
          &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 1em;
            vertical-align: 0.05em;
            border-top: 0.3em solid $transparent;
            border-right: 0;
            border-bottom: 0.3em solid $transparent;
            border-left: 0.3em solid;
          }
          svg {
            display: none;
          }
        }
      }

      & > ul {
        & > li {
          padding-left: 3.556em !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.has-child {
            &:hover {
              background: $grey-ultra-lighter !important;
              color: $blue-ultra-dark;
              li {
                color: $blue-ultra-dark;
                background: $grey-ultra-lighter;
                a {
                  color: $blue-ultra-dark;
                }
              }
            }
            & > a {
              margin-left: -3.556em !important;
              padding-left: 3.556em !important;
              width: calc(100% + 3.556em);
              color: $blue-ultra-dark;
              a {
                color: $blue-ultra-dark;
                &.collapsed {
                  &::after {
                    border-top: 0.3em solid $transparent;
                    border-right: 0;
                    border-bottom: 0.3em solid $transparent;
                    border-left: 0.3em solid;
                    vertical-align: 0.05em;
                  }
                }
              }
            }
            li {
              margin-left: -3.667em;
              &:hover {
                background: $secondary-blue;
                a {
                  color: $white;
                }
              }
            }
          }
          &:hover,
          &.selected-sm,
          &.selected {
            background: $secondary-blue;

            a {
              color: $white;
            }
          }
          & > a {
            padding: 0.5em 0;
            display: inline-block;
            color: $blue-ultra-dark;
          }

          & > ul {
            & > li {
              padding-left: 3.667em;
              border-bottom: 0;

              & > a {
                padding: 0.5em 0;
                color: $blue-ultra-dark;
                display: inline-block;
                text-transform: inherit !important;
              }
            }
          }
        }
      }
    }

    li {
      border-bottom: 1px solid $grey-lighter;
    }
  }
}

.sidebar-collapse {
  background: none;
  border: none;
  float: right;

  i.expand-list {
    display: none;
  }

  &.active {
    float: left;
    left: 0;

    i.collapse-left {
      display: none;
    }

    i.expand-list {
      display: inline-block;
    }
  }
}

.side-nav-collapsed {
  .sidenavbar{
    display: none;
  }
}


.side-nav-collapsed-t {
  & + .content {
    width: 100%;
    .budget-summary-wrap {
      width: calc(100vw - 10.5em);
    }
    .form-ctrl-wrap {
      width: calc(100vw - 10.5em);
    }
    .provider-wrap {
      width: calc(100vw - 10.5em);
    }
    .action-footer {
      width: calc(100% - 6em)!important;
    }
    .custom-grid-wrapper {
      width: calc(100vw - 10.1em);
    }
    .batch-wrap {
      width: calc(100vw - 10.5em);
    }
  }
}

.menu-sm {
  width: 5.5em !important;
  & > ul {
    & > li {
      margin-bottom: 0px !important;
      & > a {
        display: none;
      }
      & > ul.list-unstyled.collapse {
        display: block;
        & > li {
          padding: 10px 10px 10px 20px !important;
          &.has-child {
            & .arrow-right {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 10px 10px;
              border-color: transparent transparent $grey-ultra-darker;
              right: 12px;
              z-index: 999;
              top: -10px;
              position: relative;
              pointer-events: none;
            }
          }
          & > ul.show-submenu {
            display: block !important;
            position: fixed;
            left: 4.2em;
            background-color: $white;
            border: 1px solid $grey-darker;
            z-index: 9999;
            overflow: auto;
            & > li {
              margin-left: 0px !important;
              padding: 5px 25px !important;
              &.has-child {
                & .arrow-right {
                  display: none !important;
                }
              }
              &:hover {
                & a {
                  background-color: transparent !important;
                }
              }
              & > a {
                pointer-events: none;
              }
              & > ul {
                margin-left: -25px;
                margin-right: -25px;
                margin-bottom: -5px;
                background-color: $white;
                & > li {
                  margin-left: 0;
                  padding-left: 2.5em;
                  & > a {
                    background-color: transparent !important;
                  }
                }
              }
            }
          }
          &:hover {
            background: $secondary-blue !important;
            & > i {
              color: $white !important;
            }
          }
          & > i {
            width: 1.22em;
            height: 1.12em;
            display: inline-block;
            margin-right: 1.1689em;
            pointer-events: none;
          }
          & > a {
            display: none;
            & > i {
              width: 1.22em;
              height: 1.12em;
              display: inline-block;
              margin-right: 1.1689em;
            }
          }
        }
      }
    }
  }
  & .selected-sm {
    background-color: $secondary-blue;
    color: $white;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-ultra-dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey-darkest;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  svg {
    display: none;
  }
}
.dropdown-toggle.collapsed::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  svg {
    display: none;
  }
}
.sidenavbar {
  width: 17.944em;
  background: $grey-ultra-lighter;
  height: calc(100vh - 5em);
  overflow-y: auto;
  font-family: $primary-font;
  padding-bottom: 50px;
  ::-webkit-scrollbar {
    width: 6px;
    background-color: $grey-ultra-light;
  }
  & i {
    padding-right: 0.5em;
    pointer-events: none;
    display: inline-block;
    width: 30px;
    &:before {
      font-family: "cdmsfonts";
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      font-weight: normal;
      font-style: normal;
      speak: none;
      text-decoration: inherit;
      font-size: 1em;
      text-transform: none;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\ea02";
    }
    &.custservice:before {
      content: "\ea05" !important;
    }
    &.authorizations:before {
      content: "\ea04";
      font-size: 0.8em;
    }
    &.secondary-item:before {
      content: "\ea04";
      font-size: 0.8em;
    }
    &.shiftmaintenance:before {
      content: "\ea0c";
    }
    &.paycp:before {
      content: "\ea02";
    }
    &.pbr:before {
      content: "\ea02";
    }
    &.usercarriermapping:before {
      content: "\ea07";
    }
    &.agencies:before {
      content: "\ea03";
    }
  }
  & ul {
    margin: 0;
    padding: 0;
  }
  & li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    &.nochild,
    &.haschild {
      border-bottom: 1px solid $grey-lighter;
    }
    &.noparent {
      border-bottom: 0px solid $grey-lighter;
    }
    &.hasparent.haschild {
      & .nochild {
        border-bottom: 0px solid $grey-lighter;
      }
    }
    &:hover {
      & > a.hasparent {
        background-color: $secondary-blue;
        color: $white;
      }
      & > a.hasparent.haschild {
        background-color: transparent;
        color: $blue-ultra-dark;
      }
    }
  }
  & a {
    display: block;
    &:hover {
      background-color: $grey;
    }
    &.noparent {
      & span {
        font-weight: bold;
        color: $primary-green;
        font-size: 1.11em;
        padding: 10px 0px 10px 20px;
        display: block;
        pointer-events: none;
      }
    }
    &.hasparent {
      font-weight: normal;
      color: $blue-ultra-dark;
      font-size: 1em;
      padding: 2px 0px 2px 10px;
      &.selected {
        background-color: $secondary-blue;
        color: $white;
      }
    }
    &.haschild {
      & span {
        pointer-events: none;
        & .arrow {
          svg {
            display: none;
          }
          margin-left: 15px;
          display: inline-block;
          pointer-events: none;
          &.right {
            border-top: 0.3em solid transparent;
            border-right: 0;
            border-left: 0.3em solid;
            border-bottom: 0.3em solid transparent;
          }
          &.down {
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-left: 0.3em solid transparent;
            border-bottom: 0;
          }
        }
      }
    }
  }
  & .sub-menu {
    display: none;
    &.show {
      display: block;
      padding-left: 5px;
    }
  }
}
.sidenav-sm {
  width: 5.5em !important;
  & > cdms-portal-u2-nav-item {
    & > ul {
      & > li {
        & > a {
          & > span {
            display: none !important;
          }
        }
        & > .sub-menu {
          display: block;
          & > cdms-portal-u2-nav-item {
            & > ul {
              & > li {
                & > a {
                  & > span {
                    display: none !important;
                  }
                  &.hasparent{
                    padding: 2px 0px 2px 15px;
                  }
                }
                &.haschild.hasparent{
                  & a{
                    & i{
                      &::after{
                        content:"";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 0 10px 10px;
                        border-color: transparent transparent $grey-ultra-darker;
                        right: 0px;
                        z-index: 999;
                        top: -10px;
                        position: relative;
                        pointer-events: none;
                      }
                    }
                  }
                  & .sub-menu.show{
                      display: block;
                      position: fixed;
                      width: 225px;
                      background-color: $grey-ultra-lighter;
                      border:1px solid $grey-light;
                      z-index: 9;
                      left:50px;
                      overflow: auto;
                      & > cdms-portal-u2-nav-item{
                        & > ul{
                          & > li{
                            & > a{
                              &.hasparent{
                                padding: 5px 0px 5px 15px;
                                & > i{
                                  pointer-events:none;
                                  &::after{
                                    border:0;
                                  }
                                }
                                & > span{
                                  pointer-events: none;
                                }
                              }
                            }
                            &.haschild.hasparent{
                              & .sub-menu.show{
                                top:0!important;
                                left:0;
                                position: relative;
                                border:0;
                                width:223px;
                                max-height:100%!important;
                              }
                            }
                          }
                        }
                      }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  & li{
    &.haschild.hasparent{
      & a{
        & span{
          text-transform: uppercase;
        }
      }
    }
    &.nochild.hasparent{
      & a{
        & span{
          text-transform: capitalize;
        }
      }
    }
  }
}
